<div [class]="'ui-block card-type-' + cardType">
    <div class="badge-star"
        *ngIf="(user.appointment_accepted != '0' || user.appointment_pending != '0') && cardType == 'user'"
        [ngClass]="{'appointment-accepted':user.appointment_accepted != '0','appointment-pending':user.appointment_pending != '0'}">
        <i class="far fa-user-clock"></i>
        <span></span>
    </div>

    <div class="ui-block-content pb-3">
        <div class="Pic">
            <img class="profil-pic" [src]="user.profilePicture">
            <span class="country-flag">
                <img [ngbTooltip]="user?.country | crValue:'country'"
                    [src]="'/assets/img/flags/svg/'+(user.country | lowercase)+'.svg'">
            </span>
            <span class="d-block mt-1">
                {{ user?.country === 'GB' ? 'United Kingdom' : (user?.country | crValue:'country') }}
            </span>
        </div>
        <div class="title">
            <h4 class="names mb-0">
                <span>{{user.first_name}} {{user.last_name}}</span>
            </h4>
            <div class="other-info">
                <span class="user-post d-block" *ngIf="user.participantFunction">
                    <!-- <i class="far fa-user-tag"></i> -->
                    {{user.participantFunction}}
                </span>
                <span class="with-icon user-post d-block" *ngIf="user.society">
                    <svg class="olymp-company-two-icon">
                        <use xlink:href="assets/svg-icons//sprites/icons.svg#olymp-company-two-icon"></use>
                    </svg>
                    {{user.society}}
                </span>
            </div>
        </div>
    </div>

    <span class="booking-appointment" tooltipClass="tooltipClass" *ngIf="existAppointments && cardType == 'user'"
        [ngbTooltip]="user.appointment_accepted != '0' || user.appointment_pending != '0' ? ('ALREADY_SCHEDULED' | translate) : null">
        <button
            [disabled]="user.appointment_accepted != '0' || user.appointment_pending != '0' || connectedUser['ID'] == user.userId"
            class="btn btn-md-2 btn-primary appointment mb-0" (click)="appointmentRequest(user)">
            {{'APPOINTMENT' | translate}}
        </button>
    </span>

    <div class="footer-card mt-3">
        <button class="btn profil" (click)="viewProfile(user)">
            <svg class="olymp-happy-face-icon">
                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon"></use>
            </svg>
            {{'VIEW_PROFIL' | translate}}
        </button>
        <button class="btn chat" (click)="onChatClick()"
            [attr.disabled]="connectedUser['ID'] == user.userId ? 'disabled': null">
            <svg class="olymp-chat---messages-icon">
                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon"></use>
            </svg>
            {{'CHAT' | translate}}
        </button>
        <!-- <button class="btn favorite" (click)="toggleFavorite(user)" [class.active]="isFavorite(user)">
            <svg class="olymp-star-icon">
                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-star-icon"></use>
            </svg>
        </button> -->

        <button class="btn favorite" (click)="toggleFavorite(user.userId)" [class.active]="isFavorited">
            <svg class="olymp-star-icon">
              <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-star-icon"></use>
            </svg>
          </button>

    </div>
</div>
<div class="modal" *ngIf="isModalVisible">
  <div class="modal-content">
    <div class="loader" ></div>
    <p *ngIf="isModalVisible">{{ 'REDIRECTION_IN_PROGRESS' | translate }}</p>
  </div>
</div>

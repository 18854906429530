import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  private url = environment.apis.document

  constructor(private http: HttpClient) { }

  uploadFile(file: File, progress: boolean = false): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('mimeType', file.type);


  const token = sessionStorage.getItem('token') || localStorage.getItem('token');

  // If no token is found, handle the error (optional)
  if (!token) {
    console.error('No authentication token found!');
    return;
  }

  const headers = new HttpHeaders({
    'Authorization': `Bearer ${token}`
  });

    if (progress) {
      return this.http.post(this.url + 'wp-json/wp/v2/media', formData, { headers, reportProgress: true, observe: 'events' }).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return Math.round(100 * event.loaded / event.total);
            case HttpEventType.Response:
              return [
                {
                  id: event.body["source_url"],
                  fileName: event.body["slug"],
                  fileType: event.body["mime_type"],
                  upload_file: event.body["media_details"].file,
                  source_url: event.body['source_url']
                }
              ];
          }
        })
      )
    } else{
      return this.http.post(this.url + 'wp-json/wp/v2/media', formData, { headers })
    }
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getMediaById(id): Observable<any>{
    return this.http.get(this.url + 'wp-json/wp/v2/media/'+id)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
    public apiUrl = environment.apis.baseUrl;


  constructor(private http: HttpClient) {}

//   // Méthode pour générer un token
//   generateToken(clientId: string, clientSecret: string): Observable<any> {
//     const params = new HttpParams()
//       .set('client_id', clientId)
//       .set('client_secret', clientSecret);

//     return this.http.get(`${this.apiUrl}/get-client-token`, { params });
//   }

//   // Méthode pour authentifier l'utilisateur
//   loginSSO(email: string, provider: string, providerId: string, name: string, phone: string, application: string): Observable<any> {
//     const body = {
//       email,
//       provider,
//       provider_id: providerId,
//       name,
//       phone,
//       application
//     };

//     return this.http.post(`${this.apiUrl}/login-sso`, body);
//   }


// Dans AuthService
loginSSOBackend(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/login-sso-backend`, data);
}

}

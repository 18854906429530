<span class="close" (click)="activeModal.close('Cross click')">
  <svg class="olymp-close-icon">
    <use
      xlink:href="/assets/svg-icons/sprites/icons.svg#olymp-close-icon"
    ></use>
  </svg>
</span>

<!-- <div class="modal-body p-0">
    <div class="header-profile">
        <h2 *ngIf="veiwType == 'user'" [ngClass]="{'load-title-h2':loading}" class="mb-0 pb-2">{{company?.first_name}} {{company?.last_name}}</h2>
        <h2 *ngIf="veiwType != 'user'" [ngClass]="{'load-title-h2':loading}" class="mb-0 pb-2">{{company?.society}}</h2>
        <h6 [ngClass]="{'load-title-h4':loading}" class="mb-0"><strong>{{'COUNTRY' | translate}}</strong> : {{company?.country | crValue:'country'}}</h6>
    </div>
    <div class="pic">
        <span *ngIf="veiwType == 'user'" [ngClass]="{'load-pic':loading}"><img [src]="company?.profilePicture"/></span>
        <span *ngIf="veiwType != 'user'"[ngClass]="{'load-pic':loading}"><img [src]="company?.companyLogo ? company?.companyLogo : '/assets/img/logo-placeholder.png'"/></span>
    </div>

    <div class="control-tabs">
        <ul class="nav nav-tabs social-menu-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link" (click)="switchTab('general')" [ngClass]="{ active: activeTab === 'general' }">
                    {{'PROFILE' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="switchTab('company')" [ngClass]="{ active: activeTab === 'company' }">
                    {{'COMPANY_INFORMATIONS' | translate}}
                </a>
            </li>
        </ul>
    </div>

    <div class="body-profile">

        <ng-container *ngIf="activeTab == 'general'">

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.participantFunction">
                <h6 *ngIf="userInfo?.participantFunction">{{'PARTICIPANTFUNCTION' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.participantFunction">{{userInfo?.participantFunction}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.participantFunction && veiwType == 'user'">
                <h6 *ngIf="userInfo?.participantFunction">{{'COMPANY' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.participantFunction">{{userInfo?.society}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.sector">
                <h6 *ngIf="userInfo?.sector">{{'SECTOR' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.sector">{{userInfo?.sector | crValue:'sector'}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.phone">
                <h6 *ngIf="userInfo?.phone">Mobile : </h6>
                {{userInfo?.phone}}
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.user_email">
                <h6 *ngIf="userInfo?.phone">E-mail : </h6>
                {{userInfo?.user_email}}
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.website">
                <h6 *ngIf="userInfo?.website">{{'WEB_SITE' | translate}} : </h6>
                <a *ngIf="userInfo?.website" [href]="getClickableLink(userInfo?.website)" target="_blank">{{userInfo?.website}}</a>
            </div>

            <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="userInfo?.companyDescription">
                <h6 *ngIf="userInfo?.companyDescription">{{'ABOUT' | translate}} : </h6>
                <p>{{userInfo?.companyDescription}}</p>
            </div>

        </ng-container>

        <ng-container *ngIf="activeTab == 'company'">

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.companySize">
                <h6 *ngIf="userInfo?.companySize">{{'COMPANY_SIZE' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.companySize">{{userInfo?.companySize}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.establishmentYear">
                <h6 *ngIf="userInfo?.establishmentYear">{{'ESTABLISHMENT_YEAR' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.establishmentYear">{{userInfo?.establishmentYear}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-info':loading}" class="section-profile" *ngIf="userInfo?.companyAdress">
                <h6 *ngIf="userInfo?.companyAdress">{{'ADDRESS' | translate}} : </h6>
                <ng-container *ngIf="userInfo?.companyAdress">{{userInfo?.companyAdress}}</ng-container>
            </div>

            <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="userInfo?.products['PROPOSED']?.length">
                <h6>{{'PRODUCTS_PROPOSED' | translate}} : </h6>
                <p class="m-0 parent-list">
                    <span *ngFor="let prod of userInfo?.products['PROPOSED']">
                        {{prod.values | crTranslator}}
                    </span>
                </p>
            </div>


            <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="userInfo?.products['SEARCHED']?.length">
                <h6>{{'PRODUCTS_SEARCHED' | translate}} : </h6>
                <p class="m-0 parent-list">
                    <span *ngFor="let prod of userInfo?.products['SEARCHED']">
                        {{prod.values | crTranslator}}
                    </span>
                </p>
            </div>

            <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="userInfo?.services['PROPOSED']?.length">
                <h6>{{'SERVICES_PROPOSED' | translate}} : </h6>
                <p class="m-0 parent-list">
                    <span *ngFor="let prod of userInfo?.services['PROPOSED']">
                        {{prod.values | crTranslator}}
                    </span>
                </p>
            </div>


            <div [ngClass]="{'load-title-p':loading}" class="section-profile" *ngIf="userInfo?.services['SEARCHED']?.length">
                <h6>{{'SERVICES_SEARCHED' | translate}} : </h6>
                <p class="m-0 parent-list">
                    <span *ngFor="let prod of userInfo?.services['SEARCHED']">
                        {{prod.values | crTranslator}}
                    </span>
                </p>
            </div>

            <h4 class="mt-4" *ngIf="userMedia?.length"><i class="far fa-image"></i> Media</h4>
            <div class="media">
                <div class="item-media" *ngFor="let media of userMedia; let i=index">
                    <img [src]="media.content" (click)="veiwMedia(userMedia, i)">
                </div>
            </div>
        </ng-container>


        <button class="btn btn-primary btn-md full-width mt-4" (click)="chatRoom(company?.userId)" *ngIf="connectedUser['ID'] != userInfo?.userId">
            {{'CHAT' | translate}}
        </button>
    </div>
</div> -->

<div class="row profile-modal">
  <div
    class="col-12 col-md-4 col-lg-4 col-xl-4 px-0"
    style="background: #edf2f6"
  >
    <div class="d-flex flex-column justify-content-start w-100 h-100">
      <div class="avatar position-relative mt-lg-5 mx-lg-5 mb-lg-2 pb-0">
        <div *ngIf="veiwType == 'user'" [ngClass]="{ 'load-pic': loading }">
          <img
            [src]="userInfo?.profilePicture"
            style="
              border-radius: 50%;
              box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            "
          />
        </div>
        <div *ngIf="veiwType != 'user'" [ngClass]="{ 'load-pic': loading }">
          <img
            [src]="
              userInfo?.companyLogo
                ? userInfo?.companyLogo
                : '/assets/img/logo-placeholder.png'
            "
          />
        </div>
        <div class="country-flag">
          <img
            [ngbTooltip]="userInfo?.country | crValue : 'country'"
            [src]="
              '/assets/img/flags/svg/' + (userInfo.country | lowercase) + '.svg'
            "
          />
        </div>
      </div>
      <h6 class="text-center mb-4">
        {{ userInfo?.country | crValue : "country" }}
      </h6>
      <div
        class="nav flex-column nav-pills"
        role="tablist"
        aria-orientation="vertical"
      >
        <button
          class="nav-link active"
          id="profil-tab"
          data-toggle="pill"
          data-target="#profil"
          type="button"
          role="tab"
          aria-controls="profil"
          aria-selected="true"
        >
          <i class="far fa-user mr-2"></i>
          {{ "PROFILE" | translate }}
        </button>
        <button
          class="nav-link"
          id="infos-tab"
          data-toggle="pill"
          data-target="#infos"
          type="button"
          role="tab"
          aria-controls="infos"
          aria-selected="false"
        >
          <i class="far fa-building mr-2"></i>
          {{ "COMPANY_DETAILS" | translate }}
        </button>
        <button
          class="nav-link"
          id="media-tab"
          data-toggle="pill"
          data-target="#media"
          type="button"
          role="tab"
          aria-controls="media"
          aria-selected="false"
        >
          <i class="far fa-images mr-2"></i>
          {{ "MEDIA" | translate }}
        </button>
        <button
          class="nav-link"
          id="document-tab"
          data-toggle="pill"
          data-target="#document"
          type="button"
          role="tab"
          aria-controls="document"
          aria-selected="false"
        >
          <i class="far fa-file-alt mr-2"></i>
          Documents
        </button>
      </div>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <button
          class="btn btn-chat"
          (click)="onChatClick()"
          *ngIf="connectedUser['ID'] != userInfo?.userId"
        >
          <i class="fas fa-comment mr-1"></i>
          {{ "CHAT" | translate }}
        </button>
        <button
          class="btn btn-edit-profile"
          (click)="editProfile()"
          *ngIf="connectedUser['ID'] == userInfo?.userId"
        >
          <i class="far fa-edit mr-1"></i>
          {{ "EDIT_PROFILE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-8 col-lg-8 col-xl-8 p-0">
    <div class="tab-content" id="v-pills-tabContent">
      <!-- Header Section -->
      <div class="header-profile mb-1 pt-4 pb-3 px-3">
        <!-- full name -->
        <h2
          *ngIf="veiwType == 'user'"
          [ngClass]="{ 'load-title-h2': loading }"
          class="mb-0 pb-1"
        >
          {{ userInfo?.first_name }} {{ userInfo?.last_name }}
          <!-- <button
            class="btn btn-primary btn-md full-width mt-4"
            (click)="chatRoom(company?.userId)"
            *ngIf="connectedUser['ID'] != userInfo?.userId"
          >
            {{ "CHAT" | translate }}
          </button> -->
        </h2>
        <h2
          *ngIf="veiwType != 'user'"
          [ngClass]="{ 'load-title-h2': loading }"
          class="mb-0 pb-2"
        >
          {{ company?.society }}
        </h2>
        <div class="d-flex flex-column">
          <div>
            <!-- Functionality -->
            <p style="margin-bottom: 10px !important">
              {{ userInfo?.participantFunction }}
            </p>
          </div>
          <div>
            <ng-container *ngIf="userInfo?.participantFunction">
              <span class="d-inline-flex align-items-center">
                <i class="far fa-building mr-2"></i>
                <h6 class="mb-0 company-title">{{ userInfo?.society }}</h6>
              </span></ng-container
            >
          </div>
        </div>
      </div>

      <!-- Tab 1 : Profile -->
      <div
        class="tab-pane fade show active"
        id="profil"
        role="tabpanel"
        aria-labelledby="profil-tab"
      >
        <!-- Personal Informations Content -->
        <div class="body-content">
          <!-- <div class="d-inline-flex align-items-center">
            <i class="far fa-user mr-2"></i>
            <h5>{{ "PROFILE" | translate }}</h5>
          </div> -->
          <div>
            <div
              [ngClass]="{ 'load-title-info': loading }"
              class="section-profile"
              *ngIf="userInfo?.sector"
            >
              <h6 *ngIf="userInfo?.sector">{{ "SECTOR" | translate }} :</h6>
              <ng-container *ngIf="userInfo?.sector"
                ><p>
                  {{ userInfo?.sector | crValue : "sector" }}
                </p></ng-container
              >
            </div>

            <div
              [ngClass]="{ 'load-title-info': loading }"
              class="section-profile"
              *ngIf="userInfo?.phone"
            >
              <h6 *ngIf="userInfo?.phone">Mobile :</h6>
              <p>{{ userInfo?.phone }}</p>
            </div>

            <div
              [ngClass]="{ 'load-title-info': loading }"
              class="section-profile"
              *ngIf="userInfo?.user_email"
            >
              <h6 *ngIf="userInfo?.phone">E-mail :</h6>
              <p>{{ userInfo?.user_email }}</p>
            </div>

            <div
              [ngClass]="{ 'load-title-info': loading }"
              class="section-profile"
              *ngIf="userInfo?.website"
            >
              <h6 *ngIf="userInfo?.website">{{ "WEB_SITE" | translate }} :</h6>
              <a
                *ngIf="userInfo?.website"
                [href]="getClickableLink(userInfo?.website)"
                target="_blank"
                >{{ userInfo?.website }}</a
              >
            </div>

            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.productServices"
            >
              <h6 *ngIf="userInfo?.productServices">
                {{ "ABOUT" | translate }} :
              </h6>
              <p>{{ userInfo?.productServices }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab 2 : Company Informations -->
      <div
        class="tab-pane scrollable fade"
        id="infos"
        role="tabpanel"
        aria-labelledby="infos-tab"
      >
        <div class="body-content">
          <!-- <div class="d-inline-flex align-items-center">
            <i class="far fa-building mr-2"></i>
            <h5>{{ "COMPANY_DETAILS" | translate }}</h5>
          </div> -->
          <div>
            <div class="row">
              <div class="col col-12 col-md-6 col-lg-8 col-xl-8 pl-0">
                <!-- Company Name -->
                <!-- <div
                  [ngClass]="{ 'load-title-info': loading }"
                  class="section-profile"
                  *ngIf="userInfo?.society"
                >
                  <h6 *ngIf="userInfo?.society">
                    {{ "COMPANY_NAME" | translate }} :
                  </h6>
                  <ng-container *ngIf="userInfo?.society">
                    <p>{{ userInfo?.society }}</p></ng-container
                  >
                </div> -->
                <!-- Company Size -->
                <div
                  [ngClass]="{ 'load-title-info': loading }"
                  class="section-profile"
                  *ngIf="userInfo?.companySize"
                >
                  <h6 *ngIf="userInfo?.companySize">
                    {{ "COMPANY_SIZE" | translate }} :
                  </h6>
                  <ng-container *ngIf="userInfo?.companySize"
                    ><p>{{ userInfo?.companySize }}</p></ng-container
                  >
                </div>

                <!-- Company Creation Date -->
                <div
                  [ngClass]="{ 'load-title-info': loading }"
                  class="section-profile"
                  *ngIf="userInfo?.establishmentYear"
                >
                  <h6 *ngIf="userInfo?.establishmentYear">
                    {{ "ESTABLISHMENT_YEAR" | translate }} :
                  </h6>
                  <ng-container *ngIf="userInfo?.establishmentYear"
                    ><p>{{ userInfo?.establishmentYear }}</p></ng-container
                  >
                </div>
                <!-- Company Address -->
                <div
                  [ngClass]="{ 'load-title-info': loading }"
                  class="section-profile"
                  *ngIf="userInfo?.companyAdress"
                >
                  <h6 *ngIf="userInfo?.companyAdress">
                    {{ "ADDRESS" | translate }} :
                  </h6>
                  <ng-container *ngIf="userInfo?.companyAdress"
                    ><p>{{ userInfo?.companyAdress }}</p></ng-container
                  >
                </div>
              </div>
              <div
                class="col col-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-column align-items-center"
              >
                <span [ngClass]="{ 'load-pic': loading }"
                  ><img
                    style="border: 1px solid #00000010; border-radius: 18px"
                    [src]="
                      userInfo?.companyLogo
                        ? userInfo?.companyLogo
                        : '/assets/img/logo-placeholder.png'
                    "
                    width="120px"
                /></span>
                <p
                  class="text-center"
                  style="
                    font-weight: 400 !important;
                    opacity: 0.6 !important;
                    font-size: 12px;
                  "
                >
                  {{ "COMPANY_LOGO" | translate }}
                </p>
              </div>
            </div>

            <!-- Description Company -->
            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.companyDescription"
            >
              <h6 *ngIf="userInfo?.companyDescription">
                {{ "ABOUT" | translate }} :
              </h6>
              <p>{{ userInfo?.companyDescription }}</p>
            </div>

            <!-- Company Proposed Products -->
            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.products['PROPOSED']?.length"
            >
              <h6>{{ "PRODUCTS_PROPOSED" | translate }} :</h6>
              <p class="m-0 parent-list">
                <span *ngFor="let prod of userInfo?.products['PROPOSED']">
                  {{ prod.values | crTranslator }}
                </span>
              </p>
            </div>

            <!-- Company Searched Products -->
            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.products['SEARCHED']?.length"
            >
              <h6>{{ "PRODUCTS_SEARCHED" | translate }} :</h6>
              <p class="m-0 parent-list">
                <span *ngFor="let prod of userInfo?.products['SEARCHED']">
                  {{ prod.values | crTranslator }}
                </span>
              </p>
            </div>

            <!-- Company Services Proposed -->
            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.services['PROPOSED']?.length"
            >
              <h6>{{ "SERVICES_PROPOSED" | translate }} :</h6>
              <p class="m-0 parent-list">
                <span *ngFor="let prod of userInfo?.services['PROPOSED']">
                  {{ prod.values | crTranslator }}
                </span>
              </p>
            </div>

            <!-- Company Searched Services -->
            <div
              [ngClass]="{ 'load-title-p': loading }"
              class="section-profile"
              *ngIf="userInfo?.services['SEARCHED']?.length"
            >
              <h6>{{ "SERVICES_SEARCHED" | translate }} :</h6>
              <p class="m-0 parent-list">
                <span *ngFor="let prod of userInfo?.services['SEARCHED']">
                  {{ prod.values | crTranslator }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab 3 : Media -->
      <div
        class="tab-pane fade"
        id="media"
        role="tabpanel"
        aria-labelledby="media-tab"
      >
        <div class="body-content">
          <div
            class="placeholder h-100 w-100 d-flex flex-column justify-content-center align-items-center p-5"
            *ngIf="userMedia?.length == 0"
          >
            <img src="assets/img/empty-media.svg" alt="" width="150px" />
            <p>{{ "NO_MEDIA" | translate }}</p>
          </div>
          <!-- <div
            class="d-inline-flex align-items-center mb-2"
            *ngIf="userMedia?.length"
          >
            <i class="far fa-images mr-2"></i>
            <h5>{{ "MEDIA" | translate }}</h5>
          </div> -->
          <div class="media">
            <div
              class="item-media"
              *ngFor="let media of userMedia; let i = index"
            >
              <img [src]="media.content" (click)="veiwMedia(userMedia, i)" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show"
        id="document"
        role="tabpanel"
        aria-labelledby="document-tab"
      >
        <!-- Documents Content -->
        <div class="body-content">
          <div
            class="placeholder h-100 w-100 d-flex flex-column justify-content-center align-items-center p-5"
            *ngIf="userDocument?.length == 0"
          >
            <img src="assets/img/file-invoice.svg" alt="" width="150px" />
            <p>{{ "NO_DOCUMENT" | translate }}</p>
          </div>
          <div class="d-flex flex-wrap">
            <ng-container *ngFor="let file of userDocument; let i = index">
              <div
                [style]="'order:' + i"
                class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mb-3"
              >
                <div class="attached-files-item">
                  <a [href]="file?.content" target="_blank">
                    <img
                      [src]="
                        '/assets/img/file-extession/' + file?.extension + '.png'
                      "
                    />
                    <span>{{ file?.name }}</span>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" *ngIf="isModalVisible">
    <div class="modal-content">
      <div class="loader" ></div>
      <p *ngIf="isModalVisible">{{ 'REDIRECTION_IN_PROGRESS' | translate }}</p>
    </div>
  </div>


</div>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {
  public apiUrl = environment.apis.baseUrl;
  private openMsgAction = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient) { }

  getEvents(): Observable<any>{
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');

  // If no token is found, handle the error (optional)
  if (!token) {
    console.error('No authentication token found!');
    return;
  }

  const headers = new HttpHeaders({
    'Authorization': `Bearer ${token}`
  });

    return this.http.get(this.apiUrl + '/my/events/subscription', { headers })
  }

  unseenMessageCount() {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');

  // If no token is found, handle the error (optional)
  if (!token) {
    console.error('No authentication token found!');
    return;
  }

  const headers = new HttpHeaders({
    'Authorization': `Bearer ${token}`
  });
    return this.http.get<any>(this.apiUrl + `/messages/number-waiting`, { headers })
  }

}

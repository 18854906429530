import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImgCropperComponent } from 'src/app/shared/components/img-cropper/img-cropper.component';
import { User } from 'src/app/shared/models/user.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import genderCr from '../../../../assets/data-json/gender.json';
import countryCR from '../../../../assets/data-json/countrys.json';
import sectorCR from '../../../../assets/data-json/sector.json';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-first-login-form',
  templateUrl: './first-login-form.component.html',
  styleUrls: ['./first-login-form.component.scss']
})
export class FirstLoginFormComponent implements OnInit {
  profileForm: FormGroup;
  proForm: FormGroup;
  curentUser: User;
  submited: boolean = false;
  public genders = genderCr;
  public countrys = countryCR;
  public sectors = sectorCR;
  loadingSave: boolean = false;
  private sourceUrl = environment.apis.document_path
  userInfo: any;
  activeTabs: boolean[] = [true, true];
  size = [
    {
      label: "0 - 10",
      value: "0 - 10"
    },
    {
      label: "11 - 50",
      value: "11 - 50"
    },
    {
      label: "51 - 100",
      value: "51 - 100"
    },
    {
      label: "101 - 500",
      value: "101 - 500"
    },
    {
      label: "501 - 1000",
      value: "501 - 1000"
    },
    {
      label: "1001 " + this.translate.instant("AND_MORE"),
      value: "1001 et plus"
    }
  ];

  private paginationArgs = { pageNumber: 1, pageSize: 7 }

  public paginationsArgs = {
    services_searched: { pageNumber: 1, pageSize: 7 },
    services_proposed: { pageNumber: 1, pageSize: 7 },
    products_searched: { pageNumber: 1, pageSize: 7 },
    products_proposed: { pageNumber: 1, pageSize: 7 }
  }

  services_proposed$: Observable<any>;
  services_searched$: Observable<any>;
  services_proposed: any;
  services_searched: any;
  servicesCount: number;

  products_proposed$: Observable<any>;
  products_searched$: Observable<any>;
  products_proposed: any;
  products_searched: any;
  productsCount: number;

  public userLang: string = localStorage.getItem('lang');
  interval: NodeJS.Timeout;
  attributs = {
    attributs: [
      "userId",
      "user_email",
      "first_name",
      "last_name",
      "gender",
      "role",
      "profilePicture",
      "country",
      "phone",
      "participantFunction",
      "sector",
      "society",
      "productServices",
      "language",
      "website",
      "establishmentYear",
      "companySize",
      "companyLogo",
      "companyAdress",
      "services",
      "products",
      "tags",
      "companyDescription"
    ]
  };
  terms = false;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.curentUser = JSON.parse(localStorage.getItem('me'));
    this.initForm();
    this.getCrValues();
    this.initProForm();

    this.userService.getUserByID(this.curentUser.ID).subscribe(userInfo => {
      this.userInfo = Object.assign({}, userInfo.Data[0]);
      this.updateForm(userInfo.Data[0]);
    })
  }
  closeModal(): void {
    this.activeModal.dismiss();
  }

  initForm() {
    this.profileForm = this.formBuilder.group({
      ID: new FormControl(null),
      userId: new FormControl(null),
      email: new FormControl(null, Validators.required),
      username: new FormControl(null, Validators.required),
      first_name: new FormControl(null, Validators.required),
      last_name: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      role: new FormControl(null, Validators.required),
      profilePicture: new FormControl(null),
      country: new FormControl(null),
      phone: new FormControl(null),
      participantFunction: new FormControl(null),
      sector: new FormControl(null, Validators.required),
      society: new FormControl(null),
      productServices: new FormControl(null),
      language: new FormControl(null),
      website: new FormControl(null)
    })
  }

  updateForm(data) {
    this.profileForm.patchValue({
      ID: data.userId,
      userId: data.userId,
      email: data.user_email,
      username: this.curentUser.username,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      role: data.role,
      profilePicture: data.profilePicture,
      country: data.country,
      phone: data.phone,
      participantFunction: data.participantFunction,
      sector: data.sector,
      society: data.society,
      productServices: data.productServices,
      language: data.language,
      website: data.website
    })
  }

  initProForm() {
    this.proForm = this.formBuilder.group({
      establishmentYear: new FormControl(null, Validators.required),
      companySize: new FormControl(null, Validators.required),
      companyLogo: new FormControl(null),
      companyAdress: new FormControl(null, Validators.required),
      add_products_proposed: new FormControl([]),
      add_products_searched: new FormControl([]),
      add_services_proposed: new FormControl([]),
      add_services_searched: new FormControl([]),
      tags: new FormControl(null),
      companyDescription: new FormControl(null),
    })
  }

  pictuteChange(event, controle) {
    const fileList: FileList = event.target.files;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.openCropperModal(e.target.result, fileList[0].name, controle);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openCropperModal(srcfile, fileName: string, controle) {
    const modalRef = this.modalService.open(ImgCropperComponent, {
      size: 'xl',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.srcFile = srcfile;
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.ratio = 1;
    modalRef.result.then((result) => {
      if (result && result != "Cross click") {
        if (controle == 'profilePicture') {
          this.profileForm.get('profilePicture').setValue(this.sourceUrl + result.MediaMetadata.upload_file);
        } else if (controle == 'logo') {
          this.proForm.get('companyLogo').setValue(this.sourceUrl + result.MediaMetadata.upload_file);
        }
      }
    });
  }

  clearCover() {
    this.profileForm.get('profilePicture').setValue(this.curentUser.profilePicture ? this.curentUser.profilePicture : '');
  }

  clearLogo() {
    this.proForm.get('companyLogo').setValue(this.curentUser.companyLogo ? this.curentUser.companyLogo : '');
  }

  focusedField(event) {
    if (event.type == 'focus') {
      event.target.closest('.label-floating').classList.add('is-focused');
    } else {
      event.target.closest('.label-floating').classList.remove('is-focused');
    }
  }

  resetForm() {
    this.profileForm.patchValue({
      email: this.userInfo.user_email,
      username: this.curentUser.username,
      first_name: this.userInfo.first_name,
      last_name: this.userInfo.last_name,
      gender: this.userInfo.gender,
      role: this.userInfo.role,
      profilePicture: this.userInfo.profilePicture,
      country: this.userInfo.country,
      phone: this.userInfo.phone,
      participantFunction: this.userInfo.participantFunction,
      sector: this.userInfo.sector,
      society: this.userInfo.society,
      productServices: this.userInfo.productServices,
      language: this.userInfo.language,
      website: this.userInfo.website
    })
    if (this.proForm) {
      this.resetProForm();
    }

  }

  resetProForm() {
    this.proForm.patchValue({
      establishmentYear: this.userInfo.establishmentYear,
      companySize: this.userInfo.companySize,
      companyLogo: this.userInfo.companyLogo,
      companyAdress: this.curentUser.companyAdress,
      products: this.userInfo.products,
      services: this.userInfo.services,
      tags: this.userInfo.tags,
      companyDescription: this.userInfo.companyDescription
    })
  }

  getCrValues() {
    const processData = (data) => {
      return data.map(elm => ({
        ...elm,
        value: elm.values.find(item => item.language === this.userLang)?.value || ''
      }));
    };

    this.userService.getCRvalues('services', this.paginationArgs).subscribe(response => {
      this.services_proposed = processData(response.Data);
      this.services_searched = processData(response.Data);
      this.servicesCount = response.numberMaxRequest;
      this.services_proposed$ = of(this.services_proposed);
      this.services_searched$ = of(this.services_searched);
    });

    this.userService.getCRvalues('products', this.paginationArgs).subscribe(response => {
      this.products_proposed = processData(response.Data);
      this.products_searched = processData(response.Data);
      this.productsCount = response.numberMaxRequest;
      this.products_proposed$ = of(this.products_proposed);
      this.products_searched$ = of(this.products_searched);
    });
  }


  concatAndRemoveDuplicates(obj1, obj2) {
    const idMap = new Map();
    obj1.forEach((item) => {
      idMap.set(item.id, item);
    });
    obj2.forEach((item) => {
      if (!idMap.has(item.id)) {
        idMap.set(item.id, item);
      }
    });
    const result = Array.from(idMap.values());

    return result;
  }

  closeSelect(cr, type) {
    let selectedOBJ = this[type].filter(item => this.proForm.get(type).value.includes(item.id))
    clearInterval(this.interval);
    this.paginationsArgs[type].pageNumber = 1;
    this.userService.getCRvalues(cr, this.paginationsArgs[type]).subscribe(response => {
      let concatOBJ = this.concatAndRemoveDuplicates(response.Data, selectedOBJ);
      this[type] = concatOBJ.map(elm => {
        return Object.assign({}, elm, {
          value: elm.values.find(item => item.language == this.userLang).value
        })
      });
      this[type + '$'] = of(this[type]);
    });
  }

  getNextPageCR(type, controle) {
    if (this[type + 'Count'] > this[controle].length) {
      this.paginationsArgs[controle].pageNumber++;

      this.userService.getCRvalues(type, this.paginationsArgs[controle]).subscribe(response => {
        /* if (type == 'services') {
          this[controle] = [... this[controle], ...response.Data];
          this[controle+'$'] = of(this[controle])

        } else if (type == 'products') {
          this[controle] = [... this[controle], ...response.Data];
          this[controle+'$'] = of(this[controle])
        } */

        this[controle] = this.concatAndRemoveDuplicates(this[controle], response.Data).map(elm => {
          return Object.assign({}, elm, {
            value: elm.values.find(item => item.language == this.userLang).value
          })
        });
        this[controle + '$'] = of(this[controle])
      });
    }
  }

  searchCR(value, type, controle) {
    clearInterval(this.interval);
    this.interval = setTimeout(() => {

      let filter = { search_value: value.term };
      this.paginationsArgs[controle].pageNumber = 1;

      this.userService.getCRvalues(type, this.paginationsArgs[controle], filter).subscribe(response => {
        this[controle] = response.Data.map(elm => {
          return Object.assign({}, elm, {
            value: elm.values.find(item => item.language == this.userLang).value
          })
        });;
        this[controle + '$'] = of(this[controle]);
      });
    }, 500)
  }

  clearSearchCR(type, controle) {
    this.paginationsArgs[controle].pageNumber = 1;
    this.userService.getCRvalues(type, this.paginationsArgs[controle], {}).subscribe(response => {
      this[controle] = response.Data;
      this[controle + '$'] = of(response.Data);
    });
  }

  saveChanges() {
    this.submited = true;
    if (this.profileForm.valid && this.proForm.valid) {
      this.loadingSave = true;

      let { userId, ...valueForm } = this.profileForm.value;
      let proInformations: any;

      if (this.proForm) {
        proInformations = Object.assign({}, this.proForm.value, {
          tags: this.proForm.get('tags').value ? this.proForm.get('tags').value.map(item => {
            return item.value
          }).join(',') : ''
        })
      }

      this.userService.updateUser(valueForm).subscribe(res => {
          this.userService.updateUserCompany(proInformations).subscribe(res => {
            let updatedMe = Object.assign({}, valueForm, {
              firstLogin: false
            })
            localStorage.setItem('me', JSON.stringify(updatedMe));
            this.userService.getupdatedUser();
            this.toastService.success();
            this.loadingSave = false;
            this.activeModal.close('')
          }, error => {
            this.toastService.error();
            this.loadingSave = false;
          })

      }, error => {
        this.toastService.error();
        this.loadingSave = false;
      })
    }
  }

  openTab(index) {
    this.activeTabs[index] = !this.activeTabs[index]
  }
}

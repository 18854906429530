import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url: string = state.url;

    // Allow certain paths without authentication
    if (url === '/portal/configuration/analyses-profil') {
      return true;
    }

    // Check if the user has a token and required role in localStorage
    if (
      localStorage.getItem('token') && // Check for token
      localStorage.getItem('role') === 'ADMIN' && // Ensure role is ADMIN
      localStorage.getItem('enable') === 'true' // Ensure account is enabled
    ) {
      return true; // Allow navigation
    } else {
      // Redirect to login and clear stored user data
      this.router.navigate(['/authentication']);
      localStorage.removeItem('me');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('enable');
      return false;
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {
  public apiUrl = environment.apis.baseUrl;

  constructor(private http: HttpClient) { }

  sendMessageSupport(data) {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');

  // If no token is found, handle the error (optional)
  if (!token) {
    console.error('No authentication token found!');
    return;
  }

  const headers = new HttpHeaders({
    'Authorization': `Bearer ${token}`
  });
    return this.http.post<any>(this.apiUrl + '/reclamation/create', data, { headers })
  }
}

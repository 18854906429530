import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LanguageService } from './shared/services/language.service';
import { parse } from 'path';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirstLoginFormComponent } from './shared/modals/first-login-form/first-login-form.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {

  public static ERROR_MSG_SUPPORTED: string[] = [
    'error-ref-00001',
    'error-ref-00002',
    'error-ref-00003',
    'error-ref-00004',
    'error-ref-00005',
    'error-ref-00006',
    'error-ref-00007',
    'error-ref-00008',
    'error-ref-00009',
    'error-ref-000010',
    'error-ref-000011',
    'error-ref-000012',
    'error-ref-000013',
    'error-ref-000014',
    'error-ref-000015',
    'error-ref-000016',
  ];

  routesArray = ['/payment/success', '/payment/renew/success'];
  constructor(
    private LanguageService: LanguageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.LanguageService.setLanguage(localStorage.getItem('lang'));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      AppComponent.loadScript('/assets/js/main.js');
      AppComponent.loadScript('/assets/js/libs-init/libs-init.js');
    }, 500);

    setTimeout(() => {
      let connectedUser = JSON.parse(localStorage.getItem('me'));
      if (
        (!this.router.url.includes(this.routesArray[0]) ||
          !this.router.url.includes(this.routesArray[1])) &&
        connectedUser &&
        connectedUser.firstLogin &&
        connectedUser.enable &&
        (connectedUser.role == 'ACP_SUBSCRIBER' ||
          connectedUser.role == 'ADMIN')
      ) {
        this.firstLoginACP();
      }
    }, 100);
  }

  static loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.querySelector('body').appendChild(node);
  }

  firstLoginACP() {
    document.querySelector('body').classList.add('no-scroll');
    const modalRef = this.modalService.open(FirstLoginFormComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.company = [];
    modalRef.result.then((result) => {
      document.querySelector('body').classList.remove('no-scroll');
    });
  }
}

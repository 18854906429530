import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

interface ToastMethods {
  success(sucessMessage?: string);
  error(errorText: string);
  info(infoMessage: string);
  delete(infoMessage: string);
  dialogDelete(infoMessage: string);
}
@Injectable({ providedIn: 'root' })
export class ToastService implements ToastMethods {
  constructor(private injector: Injector,private translate: TranslateService) {}

  closeToast() {
    Swal.close();
  }

  private swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success ml-10',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  public success(customTitle?: string): void {
    Swal.fire({
      toast: true,
      icon: 'success',
      position: 'top-end',
      title: customTitle
        ? customTitle
        : this.injector.get(TranslateService).instant('TOAST.SUCCESS'),
      showConfirmButton: false,
      customClass: { popup: 'toaster' },
      timer: 2500,
    });
  }

  public error(errorText?: string): void {
    Swal.fire({
      toast: true,
      icon: 'error',
      timer: 2500,
      position: 'top-end',
      showConfirmButton: false,
      title: errorText
        ? errorText
        : this.injector.get(TranslateService).instant('TOAST.ERROR'),
    });
  }

  public info(infoMessage: string): void {
    Swal.fire({
      toast: true,
      icon: 'info',
      timer: 1500,
      position: 'top-end',
      showConfirmButton: false,
      title: infoMessage,
    });
  }

  public dialogDelete(title?: string, message?: string): Promise<any> {
    return this.swalWithBootstrapButtons.fire({
      title: title ? title : this.translate.instant('Are_you_sure'),
      text: message ? message : '',
      icon: 'warning',
      width: '400px',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    });
  }
  public dialogDataToAnnuaire(title?: string, message?: string): Promise<any> {
    return this.swalWithBootstrapButtons.fire({
      title: title ? title : this.translate.instant('Are_you_sure_you_want_to_register_all_subscribers_in_the_directory'),
      text: message ? message : '',
      icon: 'warning',
      width: '400px',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    });
  }
  toggleDateInput() {
    const checkBox = document.getElementById('checkBox') as HTMLInputElement;
    var dateInput = document.getElementById('dateInput');
    if (checkBox.checked && dateInput) {
      // Toggle the display property based on the checkbox state
      dateInput.classList.add('d-none');
    } else {
      dateInput.classList.remove('d-none');
    }
  }

  public updateSubscription(title?: string, message?: string): Promise<any> {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return this.swalWithBootstrapButtons.fire({
      title: title ? title : 'Are you sure ?',
      html: `
      <h5>${message}</h5>
      <div style="width: 100%">
      <div style="display: flex;width: 100%;align-content: center;justify-content: center;">
      <p style="margin-bottom: 0;font-size:1.3rem">
      <label for="checkBox" style="display: inline-flex;align-content: center;justify-content: center;margin-bottom: 0;">
      <input type="checkbox" id="checkBox" checked style="width: auto; margin-right:8px; width: 20px; height: 20px: margin-top:3%">
      Permanent
      <i data-toggle="tooltip" data-placement="top" title="Je déclare souhaiter devenir membre." style="opacity: .3" class="mx-2">
      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
      </i>
      </label></p>
      
      </div>
        <input type="date" id="dateInput"  class="swal2-input d-none" min="${formattedDate}" style="height:2.6rem;font-size:1rem">
      </div>
    `,
      icon: 'warning',
      width: '400px',
      preConfirm: () => {
        let endDate = document.getElementById('dateInput') as HTMLInputElement;
        let res =
          endDate.valueAsDate == null ? 'null' : endDate.value.toString();
        return res;
      },

      onOpen: () => {
        // Attach the onchange event listener to the date input after the modal is opened
        document
          .getElementById('checkBox')
          .addEventListener('change', this.toggleDateInput);
      },
      onClose: () => {
        // Remove the event listener when the modal is closed to avoid memory leaks
        document
          .getElementById('checkBox')
          .removeEventListener('change', this.toggleDateInput);
      },

      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    });
  }

  public delete(message?: string) {
    this.swalWithBootstrapButtons.fire(
      'Deleted !',
      message ? message : 'Content deleted',
      'success'
    );
  }

  public unauthorizedAction(title?: string, message?: string): Promise<any> {
    return this.swalWithBootstrapButtons.fire({
      title: title ? title : 'Action non autorisé',
      html: message + '</br>',
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'OK',
      reverseButtons: true,
    });
  }

  public dialogWarningTypingError(
    title?: string,
    message?: string
  ): Promise<any> {
    return this.swalWithBootstrapButtons.fire({
      title: title ? title : 'action non autorisé',
      html: message + '</br>',
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'OK',
      reverseButtons: true,
    });
  }

  public dialogRenewPayment() {
    return this.swalWithBootstrapButtons.fire({
      title: this.injector
        .get(TranslateService)
        .instant('SIGNIN_MESSAGE.PAYMENT_RENEW_MESSAGE'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    });
  }

  public dialogContactAdmin() {
    Swal.fire({
      icon: 'error',
      title: this.injector
        .get(TranslateService)
        .instant('SIGNIN_MESSAGE.ACCOUNT_DISABLED'),
      text: this.injector
        .get(TranslateService)
        .instant('SIGNIN_MESSAGE.CONTACT_ADMIN'),
    });
  }
}

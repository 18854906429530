// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apis: {
    // Set the base URL for your API endpoints
    //baseUrl: 'https://wp.acp.com.tn/wp-json/acp/v1', // Change this to your production URL
    //document: 'https://wp.acp.com.tn/', // Change this to your production document URL
    //document_path: 'https://wp.acp.com.tn/wp-content/uploads/', // Path for document uploads
    //auth: 'https://wp.acp.com.tn/', // Change this to your production authentication URL
    baseUrl: '/api_acp/wp-json/acp/v1',
    document: '/api_acp/',
    document_path: 'https://acp.com.tn/ACP_platform_backend/wp-content/uploads/',
    auth: '/api_acp/'
  },
  frontUrl: 'https://acp.com.tn/', // Change this to your production front-end URL
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

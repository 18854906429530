<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "COMPLET_PROFILE" | translate }}</h4>
  <!-- <button class="close-button" (click)="closeModal()">
      <i class="fas fa-times" aria-hidden="true"></i>
  </button> -->
</div>

<div class="modal-body">
    <div class="mb-3"><small>(*) {{'REQUIRED_FIELD' | translate}}</small></div>
    <div *ngIf="curentUser?.role == 'ADMIN' || curentUser?.role == 'ACP_SUBSCRIBER'" class="crumina-module crumina-heading with-title-decoration" [ngClass]="{'active': activeTabs[0]}">
        <h5 class="heading-title pb-3" (click)="openTab(0)">
            <span>
                {{'PERSONAL_INFORMATIONS' | translate}}
            </span>
            <i class="fas fa-angle-down"></i>
        </h5>
    </div>
    <form [formGroup]="profileForm">
        <div class="row" *ngIf="activeTabs[0]">
            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('first_name').value}">
                    <label class="control-label">{{'FIRSTNAME' | translate}}  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" type="text" formControlName="first_name" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('first_name')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('first_name')?.status === 'INVALID'}">
                </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('last_name').value}">
                    <label class="control-label">{{'LASTNAME' | translate}}  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" formControlName="last_name" type="text" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('last_name')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('last_name')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('email').value}">
                    <label class="control-label">E-mail  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" type="email" formControlName="email" disabled (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('email')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('email')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('username').value}">
                    <label class="control-label">{{'USERNAME' | translate}}  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" type="text" formControlName="username" disabled [ngClass]="{
                        'is-valid': submited && profileForm.get('username')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('username')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('gender').value}">
                    <label class="control-label">{{'GENDER' | translate}}  <span class="required">*</span></label>
                    <ng-select [items]="genders | crFormat" formControlName='gender' [bindLabel]="'name'" [bindValue]="'code'" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('gender')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('gender')?.status === 'INVALID'}">
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('phone').value}">
                    <label class="control-label">Mobile</label>
                    <input class="form-control" type="text" formControlName="phone" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('phone')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('phone')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('country').value}">
                    <label class="control-label">{{'COUNTRY' | translate}}</label>
                    <ng-select [items]="countrys | crFormat" formControlName='country' [bindLabel]="'name'" [bindValue]="'code'" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('country')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('country')?.status === 'INVALID'}">
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('society').value}">
                    <label class="control-label">{{'COMPANY' | translate}}</label>
                    <input class="form-control" type="text" formControlName="society" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('society')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('society')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('participantFunction').value}">
                    <label class="control-label">{{'POSITION' | translate}}</label>
                    <input class="form-control" type="text" formControlName="participantFunction" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('participantFunction')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('participantFunction')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('sector').value}">
                    <label class="control-label">{{'SECTOR' | translate}} <span class="required">*</span></label>
                    <ng-select [items]="sectors | crFormat" formControlName='sector' [bindLabel]="'name'" [bindValue]="'code'" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('sector')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('sector')?.status === 'INVALID'}">
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('website').value}">
                    <label class="control-label">{{'WEB_SITE' | translate}}</label>
                    <input class="form-control" type="text" formControlName="website" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('website')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('website')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !profileForm.get('productServices').value}">
                    <label class="control-label">{{'SERVICE_PRODUCT' | translate}}</label>
                    <textarea class="form-control" type="text" formControlName="productServices" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && profileForm.get('productServices')?.status === 'VALID',
                        'is-invalid': submited && profileForm.get('productServices')?.status === 'INVALID'}">
                    </textarea>
                </div>
            </div>

            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="img-upload-container form-group">
                    <div class="img-upload" [ngStyle]="{'background-image': 'url('+profileForm.get('profilePicture').value+')'}">
                        <svg class="#olymp-plus-icon" *ngIf="!profileForm.get('profilePicture').value">
                            <use xlink:href="assets/svg-icons//sprites/icons.svg#olymp-plus-icon"></use>
                        </svg>
                        <label for="eventCover"></label>
                        <input id="eventCover" accept="image/*" type="file" (change)="pictuteChange($event, 'profilePicture')">
                    </div>
                    <div>
                        <h4>Image de Profil</h4>
                        <p>
                            {{'UPLOAD_INFO_1' | translate}} <br>
                            200 x 200 pixels ; .jpg, .jpeg,. gif ou .png. {{'UPLOAD_INFO_2' | translate}}
                        </p>
                        <button (click)="clearCover()" class="btn btn-smoke btn-md-2 btn-light-bg mb-0">Réinitialiser</button>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <div *ngIf="curentUser?.role == 'ADMIN' || curentUser?.role == 'ACP_SUBSCRIBER'" class="crumina-module crumina-heading with-title-decoration mt-5" [ngClass]="{'active': activeTabs[1]}">
        <h5 class="heading-title pb-3" (click)="openTab(1)">
            <span>
                {{'PRO_INFORMATIONS' | translate}}
            </span>
            <i class="fas fa-angle-down"></i>
        </h5>
    </div>

    <form [formGroup]="proForm"  *ngIf="curentUser?.role == 'ADMIN' || curentUser?.role == 'ACP_SUBSCRIBER'">
        <div class="row" *ngIf="activeTabs[1]">

            <div class="col col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !proForm.get('establishmentYear').value}">
                    <label class="control-label">{{'ESTABLISHMENT_YEAR' | translate}}  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" type="number" formControlName="establishmentYear" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && proForm.get('establishmentYear')?.status === 'VALID',
                        'is-invalid': submited && proForm.get('establishmentYear')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !proForm.get('companySize').value}">
                    <label class="control-label">{{'COMPANY_SIZE' | translate}}  <span class="required">*</span></label>
                    <ng-select [items]="size" bindLabel="label" bindValue="value" formControlName="companySize" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && proForm.get('companySize')?.status === 'VALID',
                        'is-invalid': submited && proForm.get('companySize')?.status === 'INVALID'}">
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !proForm.get('companyAdress').value}">
                    <label class="control-label">{{'COMPANY_ADRESS' | translate}}  <span class="required">*</span></label>
                    <input class="form-control" placeholder="" type="text" formControlName="companyAdress" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && proForm.get('companyAdress')?.status === 'VALID',
                        'is-invalid': submited && proForm.get('companyAdress')?.status === 'INVALID'}">
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': proForm.get('add_services_proposed').value== null || proForm.get('add_services_proposed').value?.length == 0}">
                    <label class="control-label">{{'SERVICES_PROPOSED' | translate}}</label>
                    <ng-select
                        [items]="services_proposed$ | async"
                        [multiple]="true"
                        bindLabel="value"
                        bindValue="id"
                        formControlName="add_services_proposed"
                        (scrollToEnd)="getNextPageCR('services', 'services_proposed')"
                        (search)="searchCR($event, 'services', 'services_proposed')"
                        (close)="closeSelect('services', 'services_proposed')"
                        (clear)="clearSearchCR('services', 'services_proposed')"
                        (focus)="focusedField($event)"
                        (blur)="focusedField($event)"
                        [ngClass]="{
                            'is-valid': submited && proForm.get('add_services_proposed')?.status === 'VALID',
                            'is-invalid': submited && proForm.get('add_services_proposed')?.status === 'INVALID'}">
                            <ng-template ng-option-tmp let-item="item">
                                {{ item.values? (item.values | crTranslator) : ''}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': proForm.get('add_services_searched').value== null || proForm.get('add_services_searched').value?.length == 0}">
                    <label class="control-label">{{'SERVICES_SEARCHED' | translate}}</label>
                    <ng-select
                        [items]="services_searched$ | async"
                        [multiple]="true"
                        bindLabel="value"
                        bindValue="id"
                        formControlName="add_services_searched"
                        (scrollToEnd)="getNextPageCR('services', 'services_searched')"
                        (search)="searchCR($event, 'services', 'services_searched')"
                        (close)="closeSelect('services', 'services_searched')"
                        (clear)="clearSearchCR('services', 'services_searched')"
                        (focus)="focusedField($event)"
                        (blur)="focusedField($event)"
                        [ngClass]="{
                            'is-valid': submited && proForm.get('add_services_searched')?.status === 'VALID',
                            'is-invalid': submited && proForm.get('add_services_searched')?.status === 'INVALID'}">
                            <ng-template ng-option-tmp let-item="item">
                                {{ item.values? (item.values | crTranslator) : ''}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': proForm.get('add_products_proposed').value== null || proForm.get('add_products_proposed').value?.length == 0}">
                    <label class="control-label">{{'PRODUCTS_PROPOSED' | translate}}</label>
                    <ng-select
                        [items]="products_proposed$ | async"
                        [multiple]="true"
                        bindLabel="value"
                        bindValue="id"
                        formControlName="add_products_proposed"
                        (scrollToEnd)="getNextPageCR('products', 'products_proposed')"
                        (search)="searchCR($event, 'products', 'products_proposed')"
                        (close)="closeSelect('products', 'products_proposed')"
                        (clear)="clearSearchCR('products', 'products_proposed')"
                        (focus)="focusedField($event)"
                        (blur)="focusedField($event)"
                        [ngClass]="{
                            'is-valid': submited && proForm.get('add_products_proposed')?.status === 'VALID',
                            'is-invalid': submited && proForm.get('add_products_proposed')?.status === 'INVALID'}">
                            <ng-template ng-option-tmp let-item="item">
                                {{ item.values? (item.values | crTranslator) : ''}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': proForm.get('add_products_searched').value== null || proForm.get('add_products_searched').value?.length == 0}">
                    <label class="control-label">{{'PRODUCTS_SEARCHED' | translate}}</label>
                    <ng-select
                        [items]="products_searched$ | async"
                        [multiple]="true"
                        bindLabel="value"
                        bindValue="id"
                        formControlName="add_products_searched"
                        (scrollToEnd)="getNextPageCR('products', 'products_searched')"
                        (search)="searchCR($event, 'products', 'products_searched')"
                        (close)="closeSelect('products', 'products_searched')"
                        (clear)="clearSearchCR('products', 'products_searched')"
                        (focus)="focusedField($event)"
                        (blur)="focusedField($event)"
                        [ngClass]="{
                            'is-valid': submited && proForm.get('add_products_searched')?.status === 'VALID',
                            'is-invalid': submited && proForm.get('add_products_searched')?.status === 'INVALID'}">
                            <ng-template ng-option-tmp let-item="item">
                                {{ item.values? (item.values | crTranslator) : ''}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-group label-floating">
                    <tag-input
                    theme='minimal'
                    [placeholder]="'+ ' + ('NEW_TAG' | translate)"
                    formControlName="tags"
                    [displayBy]="'label'"
                    [secondaryPlaceholder]="('TAG' | translate)"
                    [ngClass]="{
                        'is-valid': submited && proForm.get('tags')?.status === 'VALID',
                        'is-invalid': submited && proForm.get('tags')?.status === 'INVALID'}">
                </tag-input>

                <span class="info"><i class="fas fa-info-circle"></i> {{'TAG_MANUAL' | translate}}</span>
                </div>
            </div>

            <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-group label-floating" [ngClass]="{'is-empty': !proForm.get('companyDescription').value}">
                    <label class="control-label">{{'DESCRIPTION' | translate}}</label>
                    <textarea class="form-control" type="text" formControlName="companyDescription" (focus)="focusedField($event)" (blur)="focusedField($event)" [ngClass]="{
                        'is-valid': submited && proForm.get('companyDescription')?.status === 'VALID',
                        'is-invalid': submited && proForm.get('companyDescription')?.status === 'INVALID'}">
                    </textarea>
                </div>
            </div>


            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="img-upload-container">
                    <div class="img-upload" [ngStyle]="{'background-image': 'url('+proForm.get('companyLogo').value+')'}">
                        <svg class="#olymp-plus-icon" *ngIf="!proForm.get('companyLogo').value">
                            <use xlink:href="assets/svg-icons//sprites/icons.svg#olymp-plus-icon"></use>
                        </svg>
                        <label for="eventLogo"></label>
                        <input id="eventLogo" accept="image/*" type="file" (change)="pictuteChange($event, 'logo')">
                    </div>
                    <div>
                        <h4>Logo</h4>
                        <p>
                            {{'UPLOAD_INFO_1' | translate}} <br>
                            200 x 200 pixels ; .jpg, .jpeg,. gif ou .png. {{'UPLOAD_INFO_2' | translate}}
                        </p>
                        <button (click)="clearLogo()" class="btn btn-smoke btn-md-2 btn-light-bg mb-0">Réinitialiser</button>
                    </div>
                </div>
            </div>

            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                <div class="checkbox">
                    <label>
                        <input (change)="terms = !terms" type="checkbox" name="optionsCheckboxes"><span class="checkbox-material"><span class="check"></span></span>
                        {{'TERMS_Q' | translate}} <a [href]="'/general-conditions'" target="_blank">{{'TERMS_CONDITIONS' | translate}}</a>
                    </label>
                </div>
            </div>
        </div>
    </form>

</div>

<div class="pt-4 px-4 text-center" *ngIf="submited && !(profileForm.valid && proForm.valid)">
    <h6 class="error-msg text-danger">
        {{'SUBMIT_FORM_ERROR' | translate}}
    </h6>
</div>

<div class="modal-footer">
    <div class="row">
        <div  class="col col-lg-6 col-md-6 col-sm-12 col-12">
            <button class="btn btn-secondary btn-lg full-width mb-0" (click)="resetForm()">{{'BUTTON.RESET' | translate}}</button>
        </div>
        <div  class="col col-lg-6 col-md-6 col-sm-12 col-12">
            <app-loader-dots
                [classList]="'btn btn-primary btn-lg full-width mb-0'"
                [label]="'BUTTON.SAVE' | translate "
                [isLoading]="loadingSave"
                [isDisabled]="!terms"
                (clicked)="saveChanges()">
            </app-loader-dots>
        </div>
    </div>
</div>

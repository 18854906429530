import { Component, OnInit } from '@angular/core';
import { SsoService } from '../sso.service';

@Component({
  selector: 'app-sso-request',
  template: `<p>SSO request initiated...</p>`,
  styleUrls: ['./sso-request.component.scss']
})
export class SsoRequestComponent implements OnInit {

  constructor(private ssoService: SsoService) { }

  ngOnInit(): void {
    this.triggerSsoRequest();
  }

  triggerSsoRequest(): void {
    this.ssoService.sendSsoRequest().subscribe(
      (response) => {
        console.log('SSO request successfully sent:', response);
      },
      (error) => {
        console.error('Error sending SSO request:', error);
        console.error('Error details:', error.error);
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  public apiUrl = environment.apis.baseUrl;

  constructor(private http: HttpClient) { }

  getFavorites(userId: string): Observable<any> {
    const params = new HttpParams().set('user_id', userId.toString());
    return this.http.get(`${this.apiUrl}/favorites`, { params });
}

  
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  public apiUrl = environment.apis.baseUrl;
  constructor(private http: HttpClient) {}

  addFavorite(userId:number,itemId: number): Observable<any> {
    return this.http.post(this.apiUrl+'/favorite', { user_id:userId , item_id: itemId });
  }
  addFavoriteParticipant(eventId: number): Observable<any> {
    return this.http.post(this.apiUrl+'/favorite/participant', { event_id:eventId });
  }
  removeFavorite(userId:number,itemId: number): Observable<any> {
    return this.http.delete(this.apiUrl+'/favorite', { params: {user_id: userId.toString(), item_id: itemId.toString() } });
  }

  isFavorite(userId: number,itemId: number): Observable<any> {
    return this.http.get(this.apiUrl+'/favorite', { params: { user_id:userId.toString() , item_id: itemId.toString() } });
  }
  
}

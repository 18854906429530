import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { LayoutsService } from '../services/layouts.service';
import { FavoritesService } from '../services/favorites.service';
import { Component, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserViewerComponent } from 'src/app/shared/modals/user-viewer/user-viewer.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  connectedUser: User;
  selectedEvent: FormGroup;
  events: Observable<any>;
  placeholder: string;
  unseenMessageCount: number;
  interval: NodeJS.Timeout;

  constructor(
    private router: Router,
    private userService: UserService,
    private layoutsService: LayoutsService,
    private formBuilder: FormBuilder,
    private favoritesService: FavoritesService,
    private modalService: NgbModal,
    private authService: AuthService

  ) { }

  ngOnInit(): void {
    this.notifMsg();
    this.initForm();
    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    this.getEventsByUser();

    this.userService.connectedUser$.subscribe(
      status => {
        if (Object.keys(status).length) {
          setTimeout(() => {
            if(localStorage.getItem('me')){
              this.connectedUser = JSON.parse(localStorage.getItem('me')) ;
            }
          }, 100);
        }
      }
    );
    this.loadFavorites();

  }

  notifMsg(){
    this.layoutsService.unseenMessageCount().subscribe(count => {
      this.unseenMessageCount = count.numberMessages
    });

    this.interval = setInterval(() => {
      this.layoutsService.unseenMessageCount().subscribe(count => {
        this.unseenMessageCount = count.numberMessages
      });
    }, 20000)
  }

  logout(){
    localStorage.removeItem('me');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('/authentication');
  }

  initForm(){
    this.selectedEvent = this.formBuilder.group({
      id: new FormControl(null)
    })
  }

  getEventsByUser() {
    this.layoutsService.getEvents().subscribe(res => {
      this.events = of(res['Data']);
    })
  }

  navigateToEvent(event) {
    this.router.navigateByUrl('/room/event/' + event.id + '/details');
  }

  settingsProfile(){
    this.selectedEvent.get('id').setValue(null)
    this.router.navigateByUrl('/portal/settings');
  }

  toggleMenu(event) {
    if (event.target.closest('.header-content-wrapper').classList.contains('open-menu')) {
      event.target.closest('.header-content-wrapper').classList.remove('open-menu');
    } else {
      event.target.closest('.header-content-wrapper').classList.add('open-menu');
    }
  }

  isModalVisible = false;
  onChatClick(): void {
    this.isModalVisible = true;

    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    const lang = localStorage.getItem('lang');
    const data = {
      clientId: '9d1a6c51-5e12-4c88-88df-54a4478dd8f2',
      clientSecret: 'sUgARnkDf5dRF96iQ7xgc3RQaGsBlpHMZlqrJnWB',
      email: this.connectedUser.email,
      provider: this.connectedUser.provider,
      providerId: this.connectedUser.provider_id,
      name: `${this.connectedUser.first_name} ${this.connectedUser.last_name}`,
      phone: this.connectedUser.phone,
      application: 'https://acp.sphere.contact',
      avatar:this.connectedUser.profilePicture
    };

    this.authService.loginSSOBackend(data).subscribe(
      (response) => {
        const accessToken = response?.data?.token?.access_token;
        const refresh_token = response?.data?.token?.refresh_token;
        const expires_in = response?.data?.token?.expires_in;

        if (accessToken) {
          const redirectUrl = `https://acp.sphere.contact?access_token=${accessToken}&refresh_token=${refresh_token}&expires_in=${expires_in}&lang=${lang}&email=test@test.test`;

          window.open(redirectUrl, 'AcpSphere');

          this.isModalVisible = false;
        } else {
          this.isModalVisible = false;
          console.error('Access token is missing in the response');
        }
      },
      (error) => {
        this.isModalVisible = false;
        console.error('Login failed:');
      }
    );
  }


  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  favorites: any[] = [];
  favoritesMenuOpen = false;


  toggleFavoritesMenu(): void {
    this.favoritesMenuOpen = !this.favoritesMenuOpen;
  }

  private loadFavorites(): void {
    this.favoritesService.getFavorites(this.connectedUser.ID).subscribe(data => {
      this.favorites = data.favorites;
    });
  }

  viewProfile(userId) {
    const modalRef = this.modalService.open(
      UserViewerComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.userId = userId;
    modalRef.result.then((result) => {
      if (result && result != "Cross click") {
      }
    });
  }
  @HostListener('document:click', ['$event'])
  closeFavoritesMenu(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.favorites-block');
    if (!clickedInside && this.favoritesMenuOpen) {
      this.favoritesMenuOpen = false;
    }
  }
}
